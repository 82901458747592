<template>
  <div>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="GridIcon"
          :statistic="stats.total_clients"
          statistic-title="Total Clients"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CastIcon"
          color="warning"

          :statistic="stats.total_applications"
          statistic-title="Total Applications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="GridIcon"
          color="success"
          :statistic="stats.started_applications"
          statistic-title="Started Applications"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="GridIcon"
          color="danger"
          :statistic="stats.applications_in_review"
          statistic-title="Applications In Review"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },
  data() {
    return {
      // Area charts
      stats: {
        total_clients: 0,
        started_applications: 0,
        applications_in_review: 0,
        total_applications: 0,
      },
    }
  },
  created() {
    // Subscribers gained
    this.$http.get('/api/agent-statistics')
      .then(response => { this.stats = response.data })
  },
  methods: {
  },
}
</script>
